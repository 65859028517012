import {
  TEST_CASES_ROUTE,
  TEST_CASE_VALIDATIONS_ROUTE,
  TEST_CASE_VALIDATION_RESULTS_ROUTE,
  UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE
} from '@/router/routeNames'

const TestCases = () => import(
  /* webpackChunkName: "test-cases" */ '@/components/validations/test-cases/TestCases.vue'
)
const TestCaseValidations = () => import(
  /* webpackChunkName: "test-case-validations" */ '@/components/validations/test-cases/TestCasesValidationsTable.vue'
)
const TestCaseValidationResults = () => import(
  /* webpackChunkName: "test-case-validation-results" */
  '@/components/validations/test-cases/validation-results/TestCaseValidationResults.vue'
)

const UnusableChoices = () => import(
  /* webpackChunkName: "unusable-choices-validation-results" */
  '@/components/validations/test-cases/unusable-choices/UnusableChoices.vue'
)

const routes = [
  {
    path: TEST_CASES_ROUTE.path,
    name: TEST_CASES_ROUTE.name,
    component: TestCases,
    meta: {
      appFeatureSubRoute: TEST_CASES_ROUTE,
      title: TEST_CASES_ROUTE.title
    },
    children: [{
      path: TEST_CASE_VALIDATIONS_ROUTE.path,
      name: TEST_CASE_VALIDATIONS_ROUTE.name,
      component: TestCaseValidations,
      meta: {
        title: TEST_CASE_VALIDATIONS_ROUTE.title
      }
    }]
  },
  {
    path: TEST_CASE_VALIDATION_RESULTS_ROUTE.path,
    name: TEST_CASE_VALIDATION_RESULTS_ROUTE.name,
    component: TestCaseValidationResults,
    meta: {
      appFeatureSubRoute: TEST_CASES_ROUTE,
      title: TEST_CASE_VALIDATION_RESULTS_ROUTE.title,
      breadcrumbs: [
        { label: TEST_CASE_VALIDATIONS_ROUTE.title, to: { name: TEST_CASE_VALIDATIONS_ROUTE.name } },
        { label: 'Validation ID: :testCaseValidationId' }
      ]
    }
  },
  {
    path: UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE.path,
    name: UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE.name,
    component: UnusableChoices,
    meta: {
      appFeatureSubRoute: TEST_CASES_ROUTE,
      title: TEST_CASE_VALIDATION_RESULTS_ROUTE.title
    }
  }
]

export default routes
